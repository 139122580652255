import { Link } from 'react-router-dom';

const Product = ({ product, onAdd }) => {
  return (
    <div className='product__card'>
      <div className='product__badge'>{product.badge}</div>
      <div className='product__image'>
        <img src={product.image} alt={product.title} />
      </div>
      <div className='product__details'>
        <span className='product__catagory'>{product.category}</span>
        <h4>
          <a href='/products'>{product.title}</a>
        </h4>
        <div className='product__bottom-details'>
          <div className='product__price'>
            <small>Ksh. {product.oldPrice.toFixed(2)}</small>Ksh.{' '}
            {product.newPrice.toFixed(2)}
          </div>
          <div className='product__btn-group'>
            <div className='product__btn'>
              <Link onClick={() => onAdd(product)}>Add</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
