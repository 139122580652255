import './cart.css';
import { Link } from 'react-router-dom';
import { AiFillCloseCircle } from 'react-icons/ai';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { AiOutlineMinusCircle } from 'react-icons/ai';
import { AiOutlinePlusCircle } from 'react-icons/ai';

const Cart = ({ visibility, cartItems, onAdd, onRemove, onClose }) => {
  const itemsPrice = cartItems.reduce((a, c) => a + c.qty * c.newPrice, 0);
  const deliveryPrice = itemsPrice > 5000 ? 0 : 200;
  const totalPrice = itemsPrice + deliveryPrice;

  return (
    <div className='modal' style={{ display: visibility ? 'block' : 'none' }}>
      <div className='container cart__container'>
        <div className='shopping-cart'>
          <div className='cart__head'>
            <h2>Cart Items</h2>
            <button className='close-btn' onClick={onClose}>
              <AiFillCloseCircle size={30} />
            </button>
          </div>
          <div className='cart__content'>
            {cartItems.length === 0 && (
              <span className='empty-text'>Cart is Empty!!</span>
            )}
            {cartItems.map((item) => (
              <div className='cart__product' key={item.id}>
                <img src={item.image} alt={item.title} />
                <div className='cart__product-info'>
                  <h3>{item.title}</h3>
                  <span className='cart__product-price'>
                    Ksh. {item.newPrice.toFixed(2)} X {item.qty}
                  </span>
                </div>
                <div className='cart__qty'>
                  <button
                    className='cart__qty-minus'
                    onClick={() => onRemove(item)}
                  >
                    <AiOutlineMinusCircle size={20} />
                  </button>
                  <p>{item.qty}</p>
                  <button
                    className='cart__qty-minus'
                    onClick={() => onAdd(item)}
                  >
                    <AiOutlinePlusCircle size={20} />
                  </button>
                </div>
                <button
                  className='cart__remove-btn'
                  onClick={() => onRemove(item)}
                >
                  <RiDeleteBin6Line size={20} />
                </button>
              </div>
            ))}
            {cartItems.length !== 0 && (
              <div className='container cart__math-container'>
                <div className='cart__math-content'>
                  <div className='row'>
                    <div className='col-l'>Sub Total :</div>
                    <div className='col-r'>Ksh. {itemsPrice.toFixed(2)}</div>
                  </div>
                  <div className='row'>
                    <div className='col-l'>Delivery Fee :</div>
                    <div className='col-r'>Ksh. {deliveryPrice.toFixed(2)}</div>
                  </div>
                  <div className='row'>
                    <div className='col-l'>
                      <strong>TOTAL :</strong>
                    </div>
                    <div className='col-r'>
                      <strong>Ksh. {totalPrice.toFixed(2)}</strong>
                    </div>
                  </div>
                </div>
                <div className='cart__checkout-btn'>
                  <Link
                    to={{ pathname: '/payment', state: { totalPrice } }}
                    className='btn'
                  >
                    Proceed to Payment
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
