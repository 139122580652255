// CONSTANTS
const MARKUP_PRICE = 5000;
const DELIVERY_FEE = 200;

// Get item
export function getCartItems() {
  return localStorage.getItem('cartItems')
    ? JSON.parse(localStorage.getItem('cartItems'))
    : [];
}

// Cart calculation
export function getCartTotal(cartItems) {
  const itemsPrice = cartItems.reduce((a, c) => a + c.qty * c.newPrice, 0);
  const deliveryPrice = itemsPrice > MARKUP_PRICE ? 0 : DELIVERY_FEE;
  const totalPrice = itemsPrice + deliveryPrice;

  return totalPrice;
}
