import Header from '../../components/Reuseable/Header';
import aboutBg from "../../images/bg's/about-bgx.png";
import StoryImg from '../../images/about/about1b.jpg';
import VisionImg from '../../images/about/about2a.jpg';
import MissionImg from '../../images/about/about1a.jpg';
import './about.css';
import { motion as m } from 'framer-motion';

const About = () => {
  return (
    <m.div
      className='about'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.65 } }}
    >
      <Header title='About Us' image={aboutBg}>
        There’s more to us than meets the eye and we would love to tell you all
        about us. We are proud of how far we’ve come and we keep aiming high.
      </Header>
      <section className='about__story'>
        <div className='container about__story-container'>
          <div className='about__section-image'>
            <img src={StoryImg} alt='Our Storyimage' />
          </div>
          <div className='about__section-content'>
            <h1>Our Story</h1>
            <p>
              Our journey started in Roysambu, Nairobi area back in 2010, in a
              small chicken coop that housed 250 broiler birds. Since then we
              have been expanding gradually in farm production and over the
              years, have grown our production capacity to over 250,000 birds
              annually. We have multiple farms and attribute these growth to the
              grace of God, hard work and vision. But again over these years, we
              have experienced the turbulences and challenges within the
              production line in our farms, and like any other business,
              suffered great losses, severally clinging to our vision to uplift
              our spirits.
            </p>
            <p>
              Kuku Spur is a farm to fork enterprise, where we grow chicken to
              maturity in the highest standards, and in conformity with
              excellent bird welfare incorporating, good farm management
              practices and bio-security. Our birds are raised using the highest
              water and feed standards and free from Antibiotics.
            </p>
            <p>
              We then process the birds (Halal) at our plant and daily deliver
              fresh Chicken to our outlets to serve our customers.
            </p>
          </div>
        </div>
      </section>
      <section className='about__vision'>
        <div className='container about__vision-container'>
          <div className='about__section-content'>
            <h2>Our Objective</h2>
            <p className='absc-textblock'>
              To make protein affordable at the dinner table, where every
              consumer will appreciate good quality chicken meat that has been
              raised locally by farmers as well as empowering the same.
            </p>
            <h2>Our Vision</h2>
            <p>To play a leading role in feeding the nation and East Africa.</p>
          </div>
          <div className='about__section-image'>
            <img src={VisionImg} alt='Our Visionimage' />
          </div>
        </div>
      </section>
      <section className='about__mission'>
        <div className='container about__mission-container'>
          <div className='about__section-image'>
            <img src={MissionImg} alt='Our Missionimage' />
          </div>
          <div className='about__section-content'>
            <h2>Our Mission</h2>
            <ol className='mission__list'>
              <li>
                <span className='mission__list-title'>Customer Focused</span> -
                To devote our resources, time and energy to add value, and to
                make a difference to a customer every day.
              </li>
              <li>
                <span className='mission__list-title'>Integrity</span> - To know
                and do what is right at all times within the production and
                supply chain.
              </li>
              <li>
                <span className='mission__list-title'>Respect</span> - To treat
                both our customers and employees with respect and dignity.
              </li>
              <li>
                <span className='mission__list-title'>
                  Continuous Improvement
                </span>{' '}
                - We are a learning organisation and aspire to improve both in
                quality production and supply to our customers.
              </li>
              <li>
                <span className='mission__list-title'>Team Oriented</span> -
                Working together, growing together.
              </li>
            </ol>
          </div>
        </div>
      </section>
    </m.div>
  );
};

export default About;
