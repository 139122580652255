import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainRoutes from './MainRoutes';
import ScrollToTop from './scrollToTop';
import Landing from './pages/landing/Landing';
import Payment from './components/Payment/Payment';

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      {loading ? (
        <div className='loader-container'>
          <div className='loader'></div>
        </div>
      ) : (
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route index element={<Landing />} exact />
            <Route path='*' element={<MainRoutes />} />
            <Route path='/payment' element={<Payment />} />
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
