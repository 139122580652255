import productBg from "../../images/bg's/products-bg.png";
import Header from '../../components/Reuseable/Header';
import Product from '../../components/Product/Product';
import './products.css';
import { motion as m } from 'framer-motion';

const Products = ({ products, onAdd, onRemove }) => {
  return (
    <m.div
      className='products'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.65 } }}
    >
      <Header title='Chicken Products' image={productBg}>
        We sell all chicken products namely:
        <br /> Full chicken (both Broilers & Kienyeji), chicken liver, gizzards,
        thighs, wings, drumsticks, hearts, chicken breasts, chicken necks and
        feet.
      </Header>
      <section className='container products__container'>
        <div className='product__container'>
          {products.map((product) => {
            return (
              <div key={product.id}>
                <Product product={product} onAdd={onAdd} onRemove={onRemove} />
              </div>
            );
          })}
        </div>
      </section>
    </m.div>
  );
};

export default Products;
