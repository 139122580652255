import { Link } from 'react-router-dom';
import Logo from '../../images/Kuku-Spur-logo.png';
import { AiFillYoutube } from 'react-icons/ai';
import { FaFacebookF } from 'react-icons/fa';
import { AiOutlineTwitter } from 'react-icons/ai';
import { AiFillInstagram } from 'react-icons/ai';
import './footer.css';

const Footer = () => {
  return (
    <footer>
      <div className='container footer__container'>
        <article>
          <Link to='/' className='logo'>
            <img src={Logo} alt='Footer Logo' />
          </Link>
          <p>
            Kuku Spur is a farm to fork enterprise, where we grow chicken to
            maturity in the highest standards in conformity with excellent bird
            welfare incorporating good farm management practices and
            bio-security.
          </p>
          <div className='footer__socials'>
            <a
              href='https://youtube.com'
              target='_blank'
              rel='noreferrer noopener'
            >
              <AiFillYoutube />
            </a>
            <a
              href='https://facebook.com'
              target='_blank'
              rel='noreferrer noopener'
            >
              <FaFacebookF />
            </a>
            <a
              href='https://twitter.com'
              target='_blank'
              rel='noreferrer noopener'
            >
              <AiOutlineTwitter />
            </a>
            <a
              href='https://instagram.com'
              target='_blank'
              rel='noreferrer noopener'
            >
              <AiFillInstagram />
            </a>
          </div>
        </article>
        <article>
          <h4>Permalinks</h4>
          <Link to='/about'>About</Link>
          <Link to='/products'>Products</Link>
          <Link to='/contact'>Contact</Link>
        </article>
        <article>
          <h4>Support</h4>
          <Link to='/s'>Blog</Link>
          <Link to='/s'>Community</Link>
          <Link to='/s'>FAQs</Link>
        </article>
        <article>
          <h4>Inquiries</h4>
          <Link to='/contact'>Contact Us</Link>
          <Link to='/s'>Support</Link>
        </article>
      </div>
      <div className='footer__copyright'>
        <small>
          &copy; {new Date().getFullYear()} Kuku Spur. All rights reserved.
          Powered by{' '}
          <a
            href='https://fb.me/kryptonitecreatives'
            target='_blank'
            rel='noreferrer noopener'
          >
            Kryptonite Creatives
          </a>
        </small>
      </div>
    </footer>
  );
};

export default Footer;
