export const links = [
  {
    name: 'Home',
    path: '/home',
  },
  {
    name: 'About',
    path: '/about',
  },
  {
    name: 'Products',
    path: '/products',
  },
  {
    name: 'Contact',
    path: '/contact',
  },
];

export const featured = [
  {
    id: 1,
    image: require('../images/featured/drumsticks.png'),
    title: 'Drumsticks',
  },
  {
    id: 2,
    image: require('../images/featured/wings.png'),
    title: 'Chicken Wings',
  },
  {
    id: 3,
    image: require('../images/featured/liver.png'),
    title: 'Liver',
  },
  {
    id: 4,
    image: require('../images/featured/breasts.png'),
    title: 'Chicken Breast',
  },
];

export const products = [
  {
    id: 1,
    badge: 'Per KG',
    image: require('../images/products/broilers.png'),
    title: 'Full Chicken (Capon 1.2 - 1.3)',
    category: ['Full Chicken , ', 'Capon'],
    oldPrice: 500,
    newPrice: 475,
  },
  {
    id: 2,
    badge: 'Per KG',
    image: require('../images/products/kienyeji1.png'),
    title: 'Full Kienyeji Chicken',
    category: ['Full Chicken , ', 'Kienyeji'],
    oldPrice: 1020,
    newPrice: 900,
  },
  {
    id: 3,
    badge: 'Per KG',
    image: require('../images/products/breasts.png'),
    title: 'Boneless Breast',
    category: ['Chicken Breast , ', 'Boneless'],
    oldPrice: 600,
    newPrice: 580,
  },
  {
    id: 4,
    badge: 'Per KG',
    image: require('../images/products/drum-sticks.png'),
    title: 'Drumsticks',
    category: ['Chicken Drumsticks , ', 'Drumsticks'],
    oldPrice: 580,
    newPrice: 550,
  },
  {
    id: 5,
    badge: 'Per KG',
    image: require('../images/products/legquarters.png'),
    title: 'Chicken Big Leg',
    category: ['Chicken Big Leg , ', 'Leg Quaters'],
    oldPrice: 440,
    newPrice: 400,
  },
  {
    id: 6,
    badge: 'Per KG',
    image: require('../images/products/wings.png'),
    title: 'Chicken Wings',
    category: ['Chicken , ', 'Wings'],
    oldPrice: 500,
    newPrice: 450,
  },
  {
    id: 7,
    badge: 'Per KG',
    image: require('../images/products/thighboneless.png'),
    title: 'Boneless Chicken Thighs',
    category: ['Chicken Thighs , ', 'Boneless'],
    oldPrice: 530,
    newPrice: 480,
  },
  {
    id: 8,
    badge: 'Per KG',
    image: require('../images/products/thighs.png'),
    title: 'Chicken Thighs',
    category: ['Chicken Thighs , ', 'Thighs'],
    oldPrice: 450,
    newPrice: 380,
  },
  {
    id: 9,
    badge: 'Per KG',
    image: require('../images/products/lollipops.png'),
    title: 'Chicken Lollipops',
    category: ['Chicken Lollipops , ', 'Lollipops'],
    oldPrice: 600,
    newPrice: 550,
  },
  {
    id: 10,
    badge: 'Per KG',
    image: require('../images/products/mincedbreast.png'),
    title: 'Minced Chicken Breast',
    category: ['Chicken Breast , ', 'Minced'],
    oldPrice: 623,
    newPrice: 600,
  },
  {
    id: 11,
    badge: 'Per KG',
    image: require('../images/products/breastonbone.png'),
    title: 'Chicken Breast on Bone',
    category: ['Chicken Breast , ', 'Breast on Bone'],
    oldPrice: 650,
    newPrice: 620,
  },
  {
    id: 12,
    badge: 'Per KG',
    image: require('../images/products/heads1.png'),
    title: 'Chicken Heads',
    category: ['Chicken Heads , ', 'Heads'],
    oldPrice: 120,
    newPrice: 80,
  },
  {
    id: 13,
    badge: 'Per KG',
    image: require('../images/products/liver.png'),
    title: 'Chicken Liver',
    category: ['Chicken , ', 'Liver '],
    oldPrice: 200,
    newPrice: 160,
  },
  {
    id: 14,
    badge: 'Per KG',
    image: require('../images/products/gizzards.png'),
    title: 'Fresh Gizzards',
    category: ['Chicken , ', 'Gizzards'],
    oldPrice: 400,
    newPrice: 370,
  },
  {
    id: 15,
    badge: 'Per KG',
    image: require('../images/products/feet.png'),
    title: 'Chicken Feet',
    category: ['Chicken , ', 'Feet '],
    oldPrice: 120,
    newPrice: 90,
  },
  {
    id: 16,
    badge: 'Per KG',
    image: require('../images/products/necks1.png'),
    title: 'Chicken Necks',
    category: ['Chicken , ', 'Necks , ', 'Dog Food'],
    oldPrice: 200,
    newPrice: 180,
  },
  {
    id: 17,
    badge: 'Per KG',
    image: require('../images/products/hearts.png'),
    title: 'Chicken Hearts',
    category: ['Chicken , ', 'Hearts'],
    oldPrice: 200,
    newPrice: 150,
  },
];
