import { Link } from 'react-router-dom';
import '../../pages/home/home.css';

const Hero = () => {
  return (
    <div className='hero'>
      <div className='container hero__container'>
        <div className='hero__left'>
          <h4>#TheBestChickenInTown</h4>
          <h1>
            Fresh & Clean <br /> Chicken For You.
          </h1>
          <p>
            Welcome to Kuku Spur, one of the leading Chicken butcheries and
            Chicken meat suppliers in Kenya. We supply most parts of Nairobi and
            it's environs.
          </p>
          <Link to='/about' className='btn lg'>
            Learn More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero;
