import '../Cart/cart.css';
import { getCartItems } from '../utils/cart';

const CartComponent = () => {
  const cartItems = getCartItems();

  return (
    <div className='payment__cart'>
      <div className='payment__cart-content'>
        {cartItems.map((item) => (
          <div className='cart__product' key={item.id}>
            <div className='cart__product-info'>
              <h3>{item.title}</h3>
            </div>
            <span className='cart__product-price'>
              Ksh. {item.newPrice.toFixed(2)} X {item.qty}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CartComponent;
