import Hero from '../../components/HomeParts/Hero';
import Showcase from '../../components/HomeParts/Showcase';
import './home.css';
import { motion as m } from 'framer-motion';

const Home = () => {
  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.65 } }}
    >
      <Hero />
      <Showcase />
    </m.div>
  );
};

export default Home;
