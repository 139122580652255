import './payment.css';
import { Link } from 'react-router-dom';
import { motion as m } from 'framer-motion';
import tillImg from '../../images/Mpesa-till.png';
import CartComponent from '../Reuseable/Cart';
import { getCartItems, getCartTotal } from '../utils/cart';

const Payment = () => {
  const cartItems = getCartItems();
  return (
    <m.div exit={{ opacity: 0, transition: { duration: 0.6 } }}>
      <div className='container payment__container'>
        <section className='payment__section'>
          <div className='payment__content'>
            <h2>Thank You For Shopping at Kuku Spur!</h2>
            <h3>Your Order:</h3>
            <CartComponent />
            <h2 className='payment__total-price'>
              TOTAL: Ksh. {getCartTotal(cartItems).toFixed(2)}
            </h2>
            <p>
              <strong>NOTE:</strong> Make your payments through the till given
              below. Once your transaction is complete,
              <br /> Please call <strong>(+254) 729 840 414</strong> or{' '}
              <strong>(+254) 710 781 496</strong> to confirm your order.{' '}
            </p>
            <p>
              <strong>
                ALSO NOTE: WE CHARGE A DELIVERY FEE OF <span>KSH. 200</span>,
                FOR ANY/ALL PURCHASES BELOW <span>KSH. 5000</span>.
              </strong>
            </p>

            <div className='payment__till'>
              <img src={tillImg} alt='' />
            </div>
            <Link to='/home' className='btn btn-lg'>
              Go To Home
            </Link>
          </div>
        </section>
      </div>
    </m.div>
  );
};

export default Payment;
