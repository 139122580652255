import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Logo from '../../images/Kuku-Spur-logo.png';
import { links } from '../../data/data';
import { GoThreeBars } from 'react-icons/go';
import { MdOutlineClose } from 'react-icons/md';
import { CgShoppingCart } from 'react-icons/cg';
import './navbar.css';
import Cart from '../Cart/Cart';

const Navbar = (props) => {
  const [isNavShowing, setIsNavShowing] = useState(false);
  const [cartVisible, setCartVisible] = useState(false);
  const { cartItems, onAdd, onRemove, countCartItems } = props;

  return (
    <>
      <Cart
        visibility={cartVisible}
        onAdd={onAdd}
        onRemove={onRemove}
        cartItems={cartItems}
        onClose={() => setCartVisible(false)}
      />
      <nav>
        <div className='container nav__container'>
          <Link to='/' className='logo' onClick={() => setIsNavShowing(false)}>
            <img src={Logo} alt='Nav Logo' />
          </Link>
          <ul
            className={`nav__links ${isNavShowing ? 'show__nav' : 'hide__nav'}`}
          >
            {links.map(({ name, path }, index) => {
              return (
                <li key={index}>
                  <NavLink
                    to={path}
                    className={({ isActive }) => (isActive ? 'active-nav' : '')}
                    onClick={() => setIsNavShowing((prev) => !prev)}
                  >
                    {name}
                  </NavLink>
                </li>
              );
            })}
          </ul>
          <div className='nav__cart' onClick={() => setCartVisible(true)}>
            <CgShoppingCart />
            {countCartItems ? (
              <span className='badge'>{countCartItems}</span>
            ) : (
              ''
            )}
          </div>
          <button
            className='nav__toggle-btn'
            onClick={() => setIsNavShowing((prev) => !prev)}
          >
            {isNavShowing ? <MdOutlineClose /> : <GoThreeBars />}
          </button>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
