import { Link } from 'react-router-dom';
import Header from '../../components/Reuseable/Header';
import aboutBg from "../../images/bg's/about-bgx.png";
import './notfound.css';
import { motion as m } from 'framer-motion';

const NotFound = () => {
  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.6 } }}
    >
      <Header title='Ooops! Sorry' image={aboutBg}>
        We can't seem to find any chicken on this page...
      </Header>
      <section className='container nf__container'>
        <div className='nf__content'>
          <h1>404</h1>
          <p>Page Not Found</p>
          <small>
            The page you are looking for might have been removed, had it's name
            changed or is temporarily unavailable.
          </small>
          <Link to='/home' className='btn btn-lg'>
            Go Home
          </Link>
        </div>
      </section>
    </m.div>
  );
};

export default NotFound;
