import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import contactBg from "../../images/bg's/contact-bg.png";
import Header from '../../components/Reuseable/Header';

import { MdOutlineEmail } from 'react-icons/md';
import { RiMessengerLine } from 'react-icons/ri';
import { BsWhatsapp } from 'react-icons/bs';
import './contact.css';
import { motion as m } from 'framer-motion';

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_5ikvubl',
        'template_rsb5igk',
        form.current,
        'Emv1bPSSMqDM-9UU5'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.65 } }}
    >
      <Header title='Contact Us' image={contactBg}>
        We would love to hear from you. Feel free to contact us through any of
        the avenues provide below for any enquiries, questions and feedback.
        <br /> We are here for you!
      </Header>
      <section>
        <div className='container contact__container'>
          <div className='contact__options'>
            <article className='contact__option'>
              <MdOutlineEmail className='contact__option-icon' />
              <h4>Email</h4>
              <h5>
                info@kukuspur.com <br />
                sales@kukuspur.com
              </h5>
              <a
                href='mailto:sales@kukuspur.com'
                target='_blank'
                rel='noreferrer noopener'
              >
                Send a message
              </a>
            </article>
            <article className='contact__option'>
              <RiMessengerLine className='contact__option-icon' />
              <h4>Messenger</h4>
              <h5>@kukuspurchicken</h5>
              <a
                href='https://m.me/kukuspurchicken'
                target='_blank'
                rel='noreferrer noopener'
              >
                Send a message
              </a>
            </article>
            <article className='contact__option'>
              <BsWhatsapp className='contact__option-icon' />
              <h4>Whatsapp</h4>
              <h5>(+254) 729 840 414</h5>
              <a
                href='https://api.whatsapp.com/send?phone=+254705422569'
                target='_blank'
                rel='noreferrer noopener'
              >
                Send a message
              </a>
            </article>
          </div>
          <form ref={form} onSubmit={sendEmail}>
            <input
              type='text'
              name='name'
              placeholder='Your Full Names'
              required
            />
            <input
              type='email'
              name='email'
              placeholder='Your Email'
              required
            />
            <textarea
              name='message'
              rows='7'
              placeholder='Your Message'
              required
            ></textarea>
            <button type='submit' className='btn btn-primary'>
              Send Message
            </button>
          </form>
        </div>
      </section>
    </m.div>
  );
};

export default Contact;
