import { Link } from 'react-router-dom';
import SectionTitle from '../Reuseable/SectionTitle';
import { featured } from '../../data/data';

const Showcase = () => {
  return (
    <section className='container featured'>
      <div className='featured__container'>
        <SectionTitle
          className='featured__section-title'
          title='Featured Products'
        />
        <div className='featured__content'>
          <div className='featured__wrapper'>
            {featured.map(({ id, image, title }) => {
              return (
                <Link to='/products' className='featured__card' key={id}>
                  <img src={image} alt={title} />
                  <h4>{title}</h4>
                </Link>
              );
            })}
          </div>
          <div className='featured__button'>
            <Link to='/products' className='btn lg'>
              View More
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Showcase;
