import { useState, useEffect, useDeferredValue } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/NavBar/Navbar';
import Home from './pages/home/Home';
import About from './pages/about/About';
import Products from './pages/products/Products';
import Contact from './pages/contact/Contact';
import NotFound from './pages/404/NotFound';
import Footer from './components/Footer/Footer';
import { AnimatePresence } from 'framer-motion';
import { products } from './data/data';

const MainRoutes = () => {
  const location = useLocation();

  const [cartItems, setCartItems] = useState([]);

  const onAdd = (product) => {
    const exist = cartItems.find((x) => x.id === product.id);
    if (exist) {
      const newCartItems = cartItems.map((x) =>
        x.id === product.id ? { ...exist, qty: exist.qty + 1 } : x
      );
      setCartItems(newCartItems);
      localStorage.setItem('cartItems', JSON.stringify(newCartItems));
    } else {
      const newCartItems = [...cartItems, { ...product, qty: 1 }];
      setCartItems(newCartItems);
      localStorage.setItem('cartItems', JSON.stringify(newCartItems));
    }
  };

  const onRemove = (product) => {
    const exist = cartItems.find((x) => x.id === product.id);
    if (exist.qty === 1) {
      const newCartItems = cartItems.filter((x) => x.id !== product.id);
      setCartItems(newCartItems);
      localStorage.setItem('cartItems', JSON.stringify(newCartItems));
    } else {
      const newCartItems = cartItems.map((x) =>
        x.id === product.id ? { ...exist, qty: exist.qty - 1 } : x
      );
      setCartItems(newCartItems);
      localStorage.setItem('cartItems', JSON.stringify(newCartItems));
    }
  };

  useEffect(() => {
    setCartItems(
      localStorage.getItem('cartItems')
        ? JSON.parse(localStorage.getItem('cartItems'))
        : []
    );
  }, []);

  const cartItemsCount = useDeferredValue(cartItems.length);

  return (
    <>
      <Navbar
        countCartItems={cartItemsCount}
        products={products}
        onAdd={onAdd}
        onRemove={onRemove}
        cartItems={cartItems}
      />
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route path='/home' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route
            path='/products'
            element={
              <Products
                products={products}
                onAdd={onAdd}
                onRemove={onRemove}
                cartItems={cartItems}
              />
            }
          />
          <Route path='/contact' element={<Contact />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </AnimatePresence>
      <Footer />
    </>
  );
};

export default MainRoutes;
