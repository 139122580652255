import { Link } from 'react-router-dom';
import Logo from '../../images/Kuku-Spur-logo-Main.png';
import './landing.css';

const Landing = () => {
  return (
    <>
      <div className='landing'>
        <div className='landing__container'>
          <div className='landing__content'>
            {/* <h1>Welcome To Kuku Spur</h1> */}
            <img src={Logo} alt='logo' className='landing__logo' />
            <p>
              Welcome to the number 1 chicken butchery in Nairobi and it's
              environs. We sell the Freshest and Cleanest chicken and chicken
              parts this side of the country.
            </p>
            <Link to='/home' className='btn lg'>
              ENTER SHOP
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
